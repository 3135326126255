import { theme } from '@damen/ui'
import styled from 'styled-components'

const Card = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasShadow'].includes(prop),
})<{ hasShadow?: boolean }>`
  line-height: 1;
  height: 100%;
  font-family: ${theme.fonts.body.style.fontFamily};
  box-shadow: ${({ hasShadow }) => (hasShadow ? 'none' : theme.shadows.whiteOnWhite.one)};
  background: ${theme.colors.white};
`

const CardContentRow = styled.div`
  padding: ${theme.layout.spacingL};
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`

const CardContent = styled.div`
  padding: ${theme.layout.spacingL};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const ColumnWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const RowWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`

const FlexWrapWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MobileHidden = styled.div`
  display: none;

  @media ${theme.legacyMediaQueries.md} {
    display: block;
  }
`

const TabletHidden = styled.div`
  display: none;

  @media ${theme.legacyMediaQueries.lg} {
    display: block;
  }
`

export { Card, CardContent, CardContentRow, ColumnWrapper, RowWrapper, MobileHidden, TabletHidden, FlexWrapWrapper }
