import React from 'react'
import { SkeletonLoader } from '@/components/UI'
import { TitleWrapper } from './styles'
import { TitleProps } from './types'

const TitleSkeleton: React.FC<TitleProps> = ({ size, marginBottom }) => {
  const getHeight = () => {
    if (size === 'small') {
      return '24px'
    }
    if (size === 'large') {
      return '42px'
    }
    return '32px'
  }
  return (
    <TitleWrapper size={marginBottom}>
      <SkeletonLoader width="100%" height={getHeight()} />
    </TitleWrapper>
  )
}

export default TitleSkeleton
