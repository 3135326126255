import styled from 'styled-components'
import { SpacerProps } from './types'

const SpacerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['fullWidth', 'bottomMargin', 'topMargin', 'leftMargin', 'rightMargin'].includes(prop),
})<SpacerProps>`
  width: ${({ fullWidth }) => (fullWidth ? `100%` : 'auto')};
  margin-bottom: ${({ bottomMargin }) => (bottomMargin ? `${bottomMargin}px` : '0px')};
  margin-top: ${({ topMargin }) => (topMargin ? `${topMargin}px` : '0px')};
  margin-left: ${({ leftMargin }) => (leftMargin ? `${leftMargin}px` : '0px')};
  margin-right: ${({ rightMargin }) => (rightMargin ? `${rightMargin}px` : '0px')};
`

export { SpacerWrapper }
