import React, { FC } from 'react'
import { useInfiniteHits } from 'react-instantsearch-core'
import { Button, Icon } from '@damen/ui'
import { Props } from './types'
import { Wrapper } from './styles'

const LoadMore: FC<React.PropsWithChildren<Props>> = ({ loadMoreText = 'Load more' }) => {
  const { isLastPage, showMore } = useInfiniteHits()

  if (isLastPage) {
    return null
  }
  return (
    <Wrapper>
      <Button.Default onClick={showMore} text={loadMoreText} iconPosition="left" icon={Icon.Plus} iconSize={14} />
    </Wrapper>
  )
}

export default LoadMore
