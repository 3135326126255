import React from 'react'

import { SkeletonLoader } from '@/components/UI'
import { Card, CardContent } from '../styles'
import TitleSkeleton from '../Title'
import Spacer from '../Spacer'

const MediaCard: React.FC = () => (
  <Card>
    <SkeletonLoader width="100%" height="272px" borderRadius={0} />
    <CardContent>
      <Spacer bottomMargin={8}>
        <SkeletonLoader width="50px" height="22px" />
      </Spacer>
      <TitleSkeleton size="default" marginBottom="small" />
    </CardContent>
  </Card>
)

export default MediaCard
