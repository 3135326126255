import styled from 'styled-components'
import { Heading, theme } from '@damen/ui'

const ThreeColumnResultsHitWrapper = styled.div`
  display: grid;
  grid-gap: ${theme.layout.spacingXL} 0;

  @media ${theme.legacyMediaQueries.md} {
    grid-gap: 40px ${theme.layout.spacingXL};
    grid-template-columns: 50fr 50fr;
  }

  @media ${theme.legacyMediaQueries.lg} {
    grid-gap: 40px;
    grid-template-columns: 33fr 33fr 33fr;
  }

  padding-top: ${theme.layout.spacingXL};
`

const FullWidthResultsHitWrapper = styled.div`
  padding-top: ${theme.layout.spacingXL};
`

const FullWidthGapResultsHitWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${theme.layout.spacingM} 0;
  margin-top: ${theme.layout.spacingXL};

  @media ${theme.legacyMediaQueries.md} {
    grid-gap: 40px 0;
  }

  @media ${theme.legacyMediaQueries.lg} {
    grid-gap: 40px 0;
  }
`

const OneColumnResultsHitWrapper = styled.div`
  margin-top: ${theme.layout.spacingM};
  margin-bottom: 0px;
`

const TwoColumnResultsHitWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 40px;

  margin-top: ${theme.layout.spacingM};

  @media ${theme.legacyMediaQueries.lg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`
const ResultsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  max-width: 872px;

  @media ${theme.legacyMediaQueries.md} {
    background: ${theme.colors.silverGrey100};
    padding: 40px;
  }
`

const ResultsTitle = styled(Heading.HeadingThree)`
  margin-top: 0;
`
export {
  ThreeColumnResultsHitWrapper,
  FullWidthResultsHitWrapper,
  FullWidthGapResultsHitWrapper,
  OneColumnResultsHitWrapper,
  TwoColumnResultsHitWrapper,
  ResultsContainer,
  ResultsTitle,
}
