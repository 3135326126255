import { theme } from '@damen/ui'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media ${theme.legacyMediaQueries.md} {
    margin-top: ${theme.layout.spacingXXL};
  }
`
export { Wrapper }
