import styled from 'styled-components'
import { Action, theme } from '@damen/ui'
import Link from '@/components/Link'

const CardWrapper = styled.div`
  width: 100%;
`
const DataListWrapper = styled.div`
  dl {
    margin: 0;
  }
`
const SingleHitWrapper = styled.div`
  margin-bottom: ${theme.layout.spacingL};
`

const StyledLink = styled(Link.VNext)`
  display: inline-flex;

  a {
    font-size: ${theme.typography.fontSizeTextSmall}px;
  }
`
const StyledNextLinkWithIcon = styled(Link.VNext)`
  ${Action.LinkStyle}

  display: flex;
  width: fit-content;
  color: ${theme.colors.blue} !important;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightMedium};

  &:hover,
  &:focus {
    svg {
      transform: translateX(4px);
    }
  }
`
const LinkContentWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 6px;
  }
`
export { CardWrapper, DataListWrapper, SingleHitWrapper, StyledLink, StyledNextLinkWithIcon, LinkContentWrapper }
