import React from 'react'
import { PaginationProps as Props } from '../types'
import MultipleIndexPagination from './MultipleIndex'
import DefaultPagination from './Default'

const Pagination = ({ isMultipleIndex, onPaginate, paginationMargin, paginationMarginMedia }: Props) => {
  return (
    <>
      {!isMultipleIndex && (
        <DefaultPagination
          onPaginate={onPaginate}
          paginationMargin={paginationMargin}
          paginationMarginMedia={paginationMarginMedia}
        />
      )}
      {isMultipleIndex && (
        <MultipleIndexPagination
          onPaginate={onPaginate}
          paginationMargin={paginationMargin}
          paginationMarginMedia={paginationMarginMedia}
        />
      )}
    </>
  )
}
export default Pagination
