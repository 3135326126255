import { Heading, theme } from '@damen/ui'
import styled from 'styled-components'

const StyledHeading = styled.h2`
  ${Heading.HeadingTwoStyle}
  text-align: left;
  margin: 0;
`

const ResultSpan = styled.span`
  margin: 0;
  font-size: ${theme.typography.fontSizeTextLarge}px;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.lineHeightText};
  text-align: left;
  color: ${theme.colors.marineBlack};

  @media ${theme.legacyMediaQueries.md} {
    margin: 0 0 5px ${theme.layout.spacingL};
    align-self: flex-end;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  width:100%
  ${Heading.HeadingTwoStyle}
  margin-bottom: ${theme.layout.spacingL};
  flex-direction: column;

  @media ${theme.legacyMediaQueries.md} {
    margin-bottom: ${theme.layout.spacingXXL};
    flex-direction: row;
  }
`

export { StyledHeading, ResultSpan, Wrapper }
