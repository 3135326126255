import { theme } from '@damen/ui'
import styled from 'styled-components'

export const DropdownFilterWrapper = styled.div`
  width: 100%;
  max-width: ${theme.layout.gridWidthInner};
  margin: 0 0 ${theme.layout.spacing} 0;

  @media ${theme.legacyMediaQueries.md} {
    max-width: 168px;
    margin: 0 ${theme.layout.spacingXL} 0 0;
  }
`

export const CheckboxFilterWrapper = styled.div`
  width: 100%;
  padding: 0 ${theme.layout.spacingXXL} 0 0;

  @media ${theme.legacyMediaQueries.md} {
    padding: 0 ${theme.layout.spacingM} 0 0;
    &:last-child {
      margin-right: 0;
    }
  }

  @media ${theme.legacyMediaQueries.xs} {
    width: 100%;
    padding: 0;
  }
`
