import React from 'react'
import { useHits } from 'react-instantsearch-core'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../Context/multiIndexAlgoliaSearchOverviewContext'
import { Title } from './styles'
import { ResultsTitleProps } from './types'

const ResultsTitle: React.FC<React.PropsWithChildren<ResultsTitleProps>> = ({ resultsText, isMultipleIndex }) => {
  const { results } = useHits({})
  const { totalnbHits } = useMultiIndexAlgoliaSearchOverviewContext()

  const totalHits = isMultipleIndex ? totalnbHits : results.nbHits

  if (totalHits === 0) {
    return null
  }

  return (
    <Title>
      {totalHits} {resultsText} &quot;{results.query}&quot;
    </Title>
  )
}

export default ResultsTitle
