import { theme } from '@damen/ui'
import styled from 'styled-components'

const SearchFieldWrapper = styled.div`
  justify-content: flex-end;
  height: max-content;
  margin-top: ${theme.layout.spacingM};
  margin-left: auto;
  width: 100%;

  @media ${theme.legacyMediaQueries.md} {
    flex-direction: row;
    width: 272px;
    margin-top: 0;
    margin-bottom: ${theme.layout.spacingSM};
  }
`

export { SearchFieldWrapper }
