import React, { useEffect, useState } from 'react'
import { useHits } from 'react-instantsearch-core'
import { Icon, theme } from '@damen/ui'
import { MultipleIndex } from '@/components/GlobalSearch/types'
import DefaultRefinementList from './default'
import { FacetOption } from '../types'
import {
  MenuIcon,
  MobileFilterButtonWrapper,
  MobileHeadingWrapper,
  MobileRefinementContainer,
  MobileRefinementInner,
  MobileRefinementOuter,
  MobileResultsWrapper,
  ModelHeading,
  RefinementFilterButton,
  RefinementSeeResultFilterButton,
} from './styles'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../Context/multiIndexAlgoliaSearchOverviewContext'
import CurrentRefinementList from '../CurrentRefinementList'
import { FiltersWrapperMargins } from '../CurrentRefinementList/styles'
import setBodyScrollLock from '@/utils/bodyScrollLock'

interface Props {
  facets: FacetOption[]
  refinementComponentType: 'dropdown' | 'checkbox'
  multipleIndexes: MultipleIndex[]
  enableSearchbox: boolean
  searchBoxPlaceholder: string
  rightComponent?: React.ComponentType<React.PropsWithChildren<any>>
  modelHeading?: string
  mobileFiltersTitle?: string
}

const RefinmentListComponent: React.FC<React.PropsWithChildren<Props>> = ({
  facets,
  refinementComponentType,
  multipleIndexes,
  enableSearchbox,
  searchBoxPlaceholder,
  rightComponent: RightComponent,
  modelHeading = 'Filter',
  mobileFiltersTitle,
}) => {
  const { totalnbHits } = useMultiIndexAlgoliaSearchOverviewContext()
  const { results } = useHits({})
  const totalresults = totalnbHits ?? results.nbHits
  const [isOpen, setIsOpen] = useState(false)
  const [filterState, setFilterState] = useState('hide')

  const variants = {
    hide: { y: 0, x: window.innerWidth, transition: { ease: 'easeOut', duration: 0.5 } },
    show: { y: 0, x: 0, transition: { ease: 'easeOut', duration: 1 } },
  }

  const handleFilterButtonOnClick = (showFilters: boolean) => {
    return () => {
      setBodyScrollLock(showFilters)
      setIsOpen(showFilters)
    }
  }

  useEffect(() => {
    const showFilters = isOpen ? 'show' : 'hide'
    setFilterState(showFilters)
  }, [isOpen])

  return (
    <>
      <RefinementFilterButton
        type="button"
        onClick={handleFilterButtonOnClick(true)}
        text="Filter results"
        icon={Icon.Filter}
        iconSize={20}
        iconPosition="left"
      />
      <MobileRefinementContainer isOpen={isOpen}>
        <MobileRefinementOuter variants={variants} animate={filterState}>
          <MobileRefinementInner>
            <MobileHeadingWrapper>
              <ModelHeading>{modelHeading}</ModelHeading>
              <MenuIcon onClick={handleFilterButtonOnClick(false)}>
                <Icon.Close width="18" height="17" fill={theme.colors.blue} />
              </MenuIcon>
            </MobileHeadingWrapper>
            <MobileResultsWrapper>
              <CurrentRefinementList
                resultsPlaceholder={searchBoxPlaceholder}
                facets={facets}
                filtersWrapper={FiltersWrapperMargins}
                showResultCount={false}
                keepSpaceWhenCountIsZero={false}
              />
              <DefaultRefinementList
                facets={facets}
                refinementComponentType={refinementComponentType}
                multipleIndexes={multipleIndexes}
                enableSearchbox={enableSearchbox}
                searchBoxPlaceholder={searchBoxPlaceholder}
                rightComponent={RightComponent}
                mobileFiltersTitle={mobileFiltersTitle}
              />
            </MobileResultsWrapper>
            {facets.length > 0 && (
              <MobileFilterButtonWrapper>
                <RefinementSeeResultFilterButton
                  key={1}
                  type="button"
                  text={`See ${totalresults} Results`}
                  icon={Icon.Filter}
                  iconSize={20}
                  iconPosition="left"
                  onClick={handleFilterButtonOnClick(false)}
                />
              </MobileFilterButtonWrapper>
            )}
          </MobileRefinementInner>
        </MobileRefinementOuter>
      </MobileRefinementContainer>
    </>
  )
}

export default RefinmentListComponent
