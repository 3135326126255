import React, { useMemo, useState } from 'react'
import { Form, Icon, Button } from '@damen/ui'
import { useRefinementList, useNumericMenu } from 'react-instantsearch-core'
import { FacetProps } from './types'
import { DropdownFilterWrapper } from './styles'
import CustomSelect, { CustomDropdownInputWrapper, CustomDropdownTextWrapper } from './customDropdown'

const DropdownFacet: React.FC<React.PropsWithChildren<FacetProps>> = ({
  type,
  filterType,
  label,
  unit,
  trueTranslation,
  falseTranslation,
}) => {
  const [numericValue, setNumericValue] = useState('')
  const { refine: refineNumeric } = useNumericMenu({
    attribute: type,
    // Items is required in the hook but we currently don't use it
    items: [{ label: 'More than' }],
  })
  const { items, refine } = useRefinementList({
    attribute: type,
    sortBy: ['isRefined', 'name:asc'],
    limit: 50,
  })

  const initialValues = useMemo(
    () =>
      items
        .filter((x) => x.isRefined)
        .map((x) => {
          return x.value
        }),
    [items],
  )

  const mappedItems = useMemo(
    () =>
      items.map((x) => {
        const translatedLabel = () => {
          // When we want to filter on a boolean attribute in Algolia, the default filter options are true and false.
          // We would like them to be more meaningful and so we added an option to pass a translation for true and false values.
          switch (x.value) {
            case 'true':
              if (trueTranslation) {
                return trueTranslation
              }
              return x.value

            case 'false':
              if (falseTranslation) {
                return falseTranslation
              }
              return x.value

            default:
              return x.value
          }
        }

        return {
          ...x,
          label: `${translatedLabel()} (${x.count})`,
        }
      }),
    [falseTranslation, items, trueTranslation],
  )

  const onSelect = (values: string[]) => {
    const combinedValues = [...initialValues, ...values]
    combinedValues.forEach((value) => refine(value))
  }

  const onChange = (e: any) => {
    setNumericValue(e.target.value)
  }

  if (filterType === 'equalOrGreaterThan') {
    const formattedLabel = unit ?? ''
    return (
      <DropdownFilterWrapper key={type}>
        <CustomSelect background="grey" border={false} placeholder={label}>
          <CustomDropdownInputWrapper>
            <Form.Input name={`Filter${type}`} value={numericValue} onChange={onChange} type="number" />
          </CustomDropdownInputWrapper>
          <CustomDropdownTextWrapper>{formattedLabel}</CustomDropdownTextWrapper>
          <Button.Default
            text=""
            hideText
            type="button"
            onClick={() => {
              // This format is used by the hook when rendering the items passed there
              // (in stead of us rendering an input with a button)
              refineNumeric(`%7B%22start%22:${numericValue}%7D`)
            }}
            iconSize={20}
            icon={Icon.LeftChevron}
          />
        </CustomSelect>
      </DropdownFilterWrapper>
    )
  }

  return (
    <DropdownFilterWrapper key={type}>
      <Form.MultiSelect
        placeholder={label}
        options={mappedItems}
        selectedOptions={initialValues}
        readOnly={items.length === 0}
        onSelect={onSelect}
        background="grey"
        border={false}
      />
    </DropdownFilterWrapper>
  )
}

export default DropdownFacet
