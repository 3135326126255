import React, { createContext, useContext, useMemo, useState } from 'react'
import { HitPerIndexNumber } from '../types'

interface Props {
  // List of indexes when using Multiple Indexes. List is used in checkboxIndexType component to filter per index.
  indexTypeRefinementList: string[]
  setIndexTypeRefinementList?: React.Dispatch<React.SetStateAction<string[]>>
  // Current Page is used in Virtual Pagination of enablining pagination when using Multiple IndexesCurrent Page is used in Virtual Pagination to enable pagination when using Multiple Indexes.
  currentPage: number
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
  // List storing index Number of hist and Number of Pages per index when using Multiple Indexes.
  hitPerIndexNumbers: HitPerIndexNumber[]
  setHitPerIndexNumbers: React.Dispatch<React.SetStateAction<HitPerIndexNumber[]>>
  // The aggregate number of Hits for all indexing in the hitPerIndexNumbers List
  totalnbHits: number
  // Return the Max page size in the hitPerIndexNumbers List
  nbPages: number
}

const Context = createContext<Props>(null)

export interface MultiIndexAlgoliaSearchOverviewContextProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

function checkRefinement(indexTypeRefinementList: string[], hitPerIndexNumber: HitPerIndexNumber): boolean {
  if (indexTypeRefinementList.length === 0) {
    return true
  }
  return indexTypeRefinementList.includes(hitPerIndexNumber.indexLabel)
}

const MultiIndexAlgoliaSearchOverviewContextProvider = ({
  children,
}: MultiIndexAlgoliaSearchOverviewContextProviderProps) => {
  const [indexTypeRefinementList, setIndexTypeRefinementList] = useState<string[]>([])
  const [hitPerIndexNumbers, setHitPerIndexNumbers] = useState<HitPerIndexNumber[]>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const totalnbHits = hitPerIndexNumbers
    ?.filter((result) => checkRefinement(indexTypeRefinementList, result))
    ?.map((h) => h.nbHits)
    ?.reduce((prev, curr) => prev + curr, 0)

  const nbPages = hitPerIndexNumbers
    ? hitPerIndexNumbers
        ?.filter((result) => checkRefinement(indexTypeRefinementList, result))
        ?.reduce((p: HitPerIndexNumber, c: HitPerIndexNumber) => (p.nbPages > c.nbPages ? p : c)).nbPages
    : 1

  const providerValue = useMemo(
    () => ({
      indexTypeRefinementList,
      setIndexTypeRefinementList,
      currentPage,
      setCurrentPage,
      hitPerIndexNumbers,
      setHitPerIndexNumbers,
      totalnbHits,
      nbPages,
    }),
    [
      indexTypeRefinementList,
      setIndexTypeRefinementList,
      currentPage,
      setCurrentPage,
      hitPerIndexNumbers,
      setHitPerIndexNumbers,
      totalnbHits,
      nbPages,
    ],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

export const useMultiIndexAlgoliaSearchOverviewContext = () => {
  return useContext(Context)
}

export default MultiIndexAlgoliaSearchOverviewContextProvider
