import { Heading, theme } from '@damen/ui'
import styled from 'styled-components'

export const Title = styled(Heading.HeadingThree)`
  margin: 0;
  width: 100%;
  max-width: 872px;

  @media ${theme.legacyMediaQueries.xs} {
    width: 100%;
    margin: 0 0 ${theme.layout.spacingM} 0;
  }
`
