import { theme } from '@damen/ui'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Link from '@/components/Link'
import { RefinementListStyleProps } from '../types'
import { scrollbarBlue } from '@/styles/scrollbars'

const RefinementList = styled.div.withConfig({
  shouldForwardProp: (prop) => !['refinementListDisplayOption'].includes(prop),
})<RefinementListStyleProps>`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  gap: ${theme.spacing.x1}px;
  max-width: ${theme.layout.gridWidthInner};

  @media ${theme.legacyMediaQueries.md} {
    align-items: center;
    flex-wrap: wrap;

    ${({ refinementListDisplayOption }) =>
      refinementListDisplayOption === 'vertical'
        ? `flex-direction: column; 
          width: 33.33%;
      `
        : `flex-direction: row;`}
  }

  @media ${theme.legacyMediaQueries.xs} {
    padding-top: 10px;
  }
`

const Label = styled.span`
  margin-left: ${theme.layout.spacing};
  font-size: ${theme.typography.fontSizeLabel}px;
  font-weight: ${theme.typography.fontWeightMedium};
`

const ModelHeading = styled.h3`
  text-align: center;
  margin-top: 2px;
  margin-bottom: 0;
  width: 100%;
`

const MobileRefinementOuter = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 15;
  height: 100vh;
  background-color: ${theme.colors.white};
`

const MobileRefinementContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  justify-content: center;
  background-color: ${theme.colors.white};
  width: 100%;
  height: 100vh;
  ${({ isOpen }) =>
    !isOpen &&
    `
    display: none;
  `}
`
const MobileHeadingWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${theme.layout.spacingL} 0;
`

// Height the 156px is a combination of the mobile title and the see results button
const MobileResultsWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 156px);
  padding: ${theme.layout.spacingM} ${theme.layout.spacingM} 0 ${theme.layout.spacingM};
  ${scrollbarBlue}
`

const MobileRefinementInner = styled.div`
  text-align: center;
  width: 100%;
`
const MenuIcon = styled.button`
  padding: 0;
  background: transparent;
  border: none;

  @media ${theme.legacyMediaQueries.lgMax} {
    position: absolute;
    right: ${theme.layout.spacingM};
    top: 50%;
    transform: translateY(-50%);
  }

  :focus {
    outline: ${theme.accessibility.outline};
    outline-offset: ${theme.layout.spacing};
  }
`

const RefinementFilterButton = styled(Link.VNext)`
  justify-content: center;
  width: 100%;
  margin: 0 0 ${theme.layout.spacingL} 0;
`

const RefinementSeeResultFilterButton = styled(Link.VNext)`
  justify-content: center;
  width: 100%;
`

const MobileFilterButtonWrapper = styled.div`
  padding: ${theme.layout.spacingM};
`

export {
  RefinementList,
  RefinementFilterButton,
  RefinementSeeResultFilterButton,
  Label,
  ModelHeading,
  MobileRefinementOuter,
  MobileRefinementContainer,
  MobileRefinementInner,
  MobileHeadingWrapper,
  MenuIcon,
  MobileResultsWrapper,
  MobileFilterButtonWrapper,
}
