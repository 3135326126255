import React from 'react'

import { SkeletonLoader } from '@/components/UI'
import { DataListWrapper } from './styles'
import { DataListProps } from './types'

const DataList: React.FC<DataListProps> = ({ id, rows = 3, compact }) => (
  <DataListWrapper compact={compact}>
    {new Array<number>(rows).map((_item, index) => (
      <React.Fragment key={`${id}dataList${index}`}>
        <span>
          <SkeletonLoader width="100%" height="16px" />
        </span>
        <div>
          <SkeletonLoader width="100%" height="16px" />
        </div>
      </React.Fragment>
    ))}
  </DataListWrapper>
)

export default DataList
