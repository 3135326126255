import React from 'react'
import { SpacerProps } from './types'
import { SpacerWrapper } from './styles'

const Spacer: React.FC<React.PropsWithChildren<SpacerProps>> = ({
  children,
  bottomMargin,
  topMargin,
  leftMargin,
  rightMargin,
  fullWidth = true,
}) => (
  <SpacerWrapper
    fullWidth={fullWidth}
    bottomMargin={bottomMargin}
    topMargin={topMargin}
    leftMargin={leftMargin}
    rightMargin={rightMargin}
  >
    {children}
  </SpacerWrapper>
)

export default Spacer
