import React, { useEffect } from 'react'
import { usePagination } from 'react-instantsearch-core'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../../Context/multiIndexAlgoliaSearchOverviewContext'

/*
Virtual Pagination updates page request in Algolia for each index in the "Index from react-instantsearch-core" when using multiple indexes 
Ref: https://discourse.algolia.com/t/pagination-with-multiple-indexes/534
*/

const VirtualPagination: React.FC<React.PropsWithChildren> = () => {
  const { currentPage, nbPages, setCurrentPage } = useMultiIndexAlgoliaSearchOverviewContext()
  const { refine } = usePagination()
  const currentpageHigerThanNumberOfPages = currentPage > nbPages

  useEffect(() => {
    // Damen Component is 1 based, though Algolia is 0 based
    refine(currentPage - 1)
  }, [currentPage, refine])

  useEffect(() => {
    // If currentPage is greater that nbpage set currentpage to 1
    setCurrentPage(1)
  }, [currentpageHigerThanNumberOfPages, setCurrentPage])

  return null
}

export default VirtualPagination
