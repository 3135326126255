import React, { MutableRefObject, useRef } from 'react'
import InstantSearch from './InstantSearch'
import { Container } from '../UI'
import Configure from './Configuration'
import CurrentRefinementList from './CurrentRefinementList'
import EmptyResults from './Empty'
import RefinementList from './RefinmentList'
import type { Props } from './types'
import { OffsetElement, ResultsContainerDefault, OverviewContainerDefault } from './styles'
import ResultsTitle from './ResultsTitle'
import SearchResultsContextProvider from './Context/multiIndexAlgoliaSearchOverviewContext'
import SingleSearchResultContextProvider from './Context/indexAlgoliaSearchOverviewContext'
import Pagination from './Pagination'
import { Hits, InfiniteHits, MultipleIndexHits } from './Hits'
import LoadMore from './LoadMore'
import OverviewTitle from './OverviewTitle'

const onPaginate = (componentRef: MutableRefObject<HTMLDivElement>) => {
  setTimeout(() => {
    componentRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }, 500)
}

const AlgoliaSearchOverview: React.FC<React.PropsWithChildren<Props>> = ({
  pageType = 'default',
  hitWrapperComponent: HitWrapper,
  hitComponent: Hit,
  overviewContainerComponent: OverviewContainer = OverviewContainerDefault,
  resultsContainerComponent: ResultsContainer = ResultsContainerDefault,
  rightComponent: RightComponent,
  facets,
  // Set default pageSize here too, used for loading state
  pageSize = 9,
  indexName,
  enableSearchbox = true,
  searchBoxPlaceholder,
  resultsPlaceholder,
  mobileFiltersTitle,
  noResultsContent,
  emptyComponent: Empty,
  pager: loadType = 'pagination',
  loadMoreText,
  defaultSearchOptions,
  title,
  resultsText = 'Results for',
  refinementComponentType = 'dropdown',
  multipleIndexes,
  enableResultsTitle = false,
  currentRefinementFiltersWrapper,
  refinementListDisplayOption = 'horizontal',
  paginationMargin = '80px 0',
  paginationMarginMedia = '116px 0',
  enableEmptyCheckForTotalHits = false,
  enableFilterForMediaButton = false,
  mainContainerCssPosition = 'relative',
  showCurrentRefinementResultCount = true,
  keepCurrentRefinementListSpaceWhenCountIsZero = true,
  sortByOptions = [],
  sortByOptions4thSpecification = [],
  sortByPlaceholder,
  initialSort,
  cmsSortOrder,
  skeletonLoader,
}) => {
  const offsetRef = useRef<HTMLDivElement>(null)
  const isMultipleIndex: boolean = multipleIndexes?.length > 1

  return (
    <Container key={`${indexName}-container`} pageType={pageType} cssPosition={mainContainerCssPosition}>
      <InstantSearch indexName={indexName}>
        <SearchResultsContextProvider>
          <SingleSearchResultContextProvider>
            <OverviewContainer>
              <OverviewTitle title={title} />
              <Configure hitsPerPage={pageSize} defaultSearchOptions={defaultSearchOptions} />
              <OffsetElement ref={offsetRef} />
              {facets.length > 0 && (
                <RefinementList
                  facets={facets}
                  refinementComponentType={refinementComponentType}
                  multipleIndexes={multipleIndexes}
                  enableSearchbox={enableSearchbox}
                  searchBoxPlaceholder={searchBoxPlaceholder}
                  rightComponent={RightComponent}
                  refinementListDisplayOption={refinementListDisplayOption}
                  enableEmptyCheckForTotalHits={enableEmptyCheckForTotalHits}
                  enableFilterButton={enableFilterForMediaButton}
                  sortByOptions={sortByOptions}
                  sortByOptions4thSpecification={sortByOptions4thSpecification}
                  sortByPlaceholder={sortByPlaceholder}
                  initialSort={initialSort}
                  mobileFiltersTitle={mobileFiltersTitle}
                />
              )}
              <ResultsContainer>
                {enableResultsTitle && <ResultsTitle resultsText={resultsText} isMultipleIndex={isMultipleIndex} />}
                <CurrentRefinementList
                  resultsPlaceholder={resultsPlaceholder}
                  facets={facets}
                  filtersWrapper={currentRefinementFiltersWrapper}
                  showResultCount={showCurrentRefinementResultCount}
                  keepSpaceWhenCountIsZero={keepCurrentRefinementListSpaceWhenCountIsZero}
                />
                <>
                  {loadType === 'pagination' && (
                    <>
                      {isMultipleIndex && (
                        <MultipleIndexHits
                          key={`${indexName}-hits`}
                          hitComponent={Hit}
                          hitWrapperComponent={HitWrapper}
                          multipleIndexes={multipleIndexes}
                          emptyComponent={
                            Empty ?? (
                              <EmptyResults
                                key={`${indexName}-emptyContainer`}
                                title={noResultsContent?.title}
                                text={noResultsContent?.text}
                              />
                            )
                          }
                          skeletonLoader={skeletonLoader}
                        />
                      )}
                      {!isMultipleIndex && (
                        <Hits
                          key={`${indexName}-hits`}
                          hitComponent={Hit}
                          emptyComponent={
                            Empty ?? (
                              <EmptyResults
                                key={`${indexName}-emptyContainer`}
                                title={noResultsContent?.title}
                                text={noResultsContent?.text}
                              />
                            )
                          }
                          hitWrapperComponent={HitWrapper}
                          cmsSortOrder={cmsSortOrder}
                          skeletonLoader={skeletonLoader}
                          pageSize={pageSize}
                        />
                      )}
                      <Pagination
                        isMultipleIndex={isMultipleIndex}
                        onPaginate={() => onPaginate(offsetRef)}
                        paginationMargin={paginationMargin}
                        paginationMarginMedia={paginationMarginMedia}
                      />
                    </>
                  )}
                  {loadType !== 'pagination' && (
                    <>
                      <InfiniteHits
                        hitComponent={Hit}
                        emptyComponent={Empty}
                        hitWrapperComponent={HitWrapper}
                        skeletonLoader={skeletonLoader}
                        pageSize={pageSize}
                      />
                      <LoadMore loadMoreText={loadMoreText} />
                    </>
                  )}
                </>
              </ResultsContainer>
            </OverviewContainer>
          </SingleSearchResultContextProvider>
        </SearchResultsContextProvider>
      </InstantSearch>
    </Container>
  )
}

export default AlgoliaSearchOverview
