import React from 'react'
import { useConfigure } from 'react-instantsearch-core'
import { ConfigurationProps } from './types'

const Configure: React.FC<React.PropsWithChildren<ConfigurationProps>> = ({
  hitsPerPage = 9,
  defaultSearchOptions,
}) => {
  useConfigure({ ...defaultSearchOptions, hitsPerPage })
  return null
}

export default Configure
