import React from 'react'

import { SkeletonLoader } from '@/components/UI'
import { Card, CardContent } from '../styles'
import DataList from '../DataList'
import TitleSkeleton from '../Title'
import Spacer from '../Spacer'

interface Props {
  id: string | number
}

const BasicCard: React.FC<Props> = ({ id }) => (
  <Card>
    <SkeletonLoader width="100%" height="272px" borderRadius={0} />
    <CardContent>
      <TitleSkeleton size="default" marginBottom="small" />
      <Spacer topMargin={14}>
        <DataList id={id} compact />
      </Spacer>
    </CardContent>
  </Card>
)

export default BasicCard
