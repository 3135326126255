import React from 'react'
import { Icon, theme } from '@damen/ui'
import { styled } from 'styled-components'

interface MobileFilterToggleProps {
  handleMobileToggleClick: () => void
  isOpen: boolean
  searchEnabled: boolean
  placeholder?: string
}

const MobileFilterToggleButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['searchEnabled', 'isOpen'].includes(prop),
})<{ searchEnabled?: boolean; isOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isOpen }) => (isOpen ? theme.colors.greyAccentLight : theme.colors.blueIce)};
  margin-top: ${theme.spacing.x2}px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  padding: ${theme.spacing.x2}px;
  gap: ${theme.spacing.x3}px;
  width: ${({ searchEnabled }) => (searchEnabled ? 'auto' : '100%')};
  transition: ${theme.timing.default} ease;

  @media ${theme.legacyMediaQueries.md} {
    display: none;
  }
`

const IconWrapper = styled.span`
  display: flex;
  width: auto;
`

const Placeholder = styled.p`
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightMedium};
  color: ${theme.colors.marineBlack};
  white-space: nowrap;
  user-select: none;
`

export const MobileFilterToggle: React.FC<React.PropsWithChildren<MobileFilterToggleProps>> = ({
  handleMobileToggleClick,
  isOpen,
  searchEnabled,
  placeholder,
}) => {
  return (
    <MobileFilterToggleButton isOpen={isOpen} onClick={handleMobileToggleClick} searchEnabled={searchEnabled}>
      <IconWrapper>
        <Icon.Filter fill={theme.colors.marineBlack} width={24} height={24} />
      </IconWrapper>
      <Placeholder>{placeholder}</Placeholder>
    </MobileFilterToggleButton>
  )
}
