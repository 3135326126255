import React from 'react'
import DefaultRefinementList from './default'
import MobileRefinementList from './mobile'
import { RefinementList } from './styles'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../Context/multiIndexAlgoliaSearchOverviewContext'
import { RefinementListProps } from './types'
import { useMobile } from '@/hooks/mobile'
import { useIndexAlgoliaSearchOverviewContext } from '../Context/indexAlgoliaSearchOverviewContext'

const RefinmentListComponent: React.FC<React.PropsWithChildren<RefinementListProps>> = ({
  facets,
  refinementComponentType,
  multipleIndexes,
  enableSearchbox,
  searchBoxPlaceholder,
  rightComponent: RightComponent,
  enableEmptyCheckForTotalHits = false,
  refinementListDisplayOption,
  enableFilterButton = false,
  sortByOptions = [],
  sortByOptions4thSpecification = [],
  sortByPlaceholder,
  initialSort,
  mobileFiltersTitle,
}) => {
  const { totalnbHits } = useMultiIndexAlgoliaSearchOverviewContext()
  const { totalIndexHits } = useIndexAlgoliaSearchOverviewContext()
  const { isMobile } = useMobile()
  
  // MultipleIndexes is null on f.e. news overview
  if ((enableEmptyCheckForTotalHits && totalnbHits === 0) || (totalIndexHits === 0 && multipleIndexes?.length === 1)) {
    return null
  }

  return (
    <RefinementList refinementListDisplayOption={refinementListDisplayOption}>
      {(!enableFilterButton || !isMobile) && (
        <DefaultRefinementList
          facets={facets}
          refinementComponentType={refinementComponentType}
          multipleIndexes={multipleIndexes}
          enableSearchbox={enableSearchbox}
          searchBoxPlaceholder={searchBoxPlaceholder}
          rightComponent={RightComponent}
          sortByOptions={sortByOptions}
          sortByOptions4thSpecification={sortByOptions4thSpecification}
          sortByPlaceholder={sortByPlaceholder}
          initialSort={initialSort}
          refinementListDisplayOption={refinementListDisplayOption}
          mobileFiltersTitle={mobileFiltersTitle}
        />
      )}
      {enableFilterButton && isMobile && (
        <MobileRefinementList
          facets={facets}
          refinementComponentType={refinementComponentType}
          multipleIndexes={multipleIndexes}
          enableSearchbox={enableSearchbox}
          searchBoxPlaceholder={searchBoxPlaceholder}
          rightComponent={RightComponent}
          mobileFiltersTitle={mobileFiltersTitle}
        />
      )}
    </RefinementList>
  )
}

export default RefinmentListComponent
