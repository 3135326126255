import { theme } from '@damen/ui'
import styled from 'styled-components'

export const SortWrapper = styled.div`
  width: 100%;
  max-width: ${theme.layout.gridWidthInner};
  margin: 0 0 ${theme.layout.spacing} 0;

  label {
    background-color: ${theme.colors.blueIce};
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.fontSizeTextSmall}px;
    border: none;

    &:hover {
      background-color: ${theme.colors.greyAccentLight};
    }
  }

  p {
    font-size: ${theme.typography.fontSizeTextSmall}px;
  }

  select {
    cursor: pointer;
  }

  @media ${theme.legacyMediaQueries.md} {
    max-width: 168px;
    margin: 0 0 ${theme.layout.spacingSM} auto;
  }
`
