import React, { useMemo, useEffect } from 'react'
import { useHits } from 'react-instantsearch-core'
import { MultipleIndex } from '@/components/GlobalSearch/types'
import { HitPerIndexNumber } from '../../types'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../../Context/multiIndexAlgoliaSearchOverviewContext'

/*
  HitPerIndexStateManager is used to store the state of the number of hits and number of pages per index when using Multiple indexes 
  the MultiIndexAlgoliaSearchOverviewContext will handle the state 
  The state is required to determine the Max Page size in the Pagination component, Total hit count in the ResultsTitle component
  and Counts per index on Refinements in the checkboxIndexType Refinement component.
*/

interface Props {
  algoliaIndex?: MultipleIndex
}

const HitPerIndexStateManager: React.FC<React.PropsWithChildren<Props>> = ({ algoliaIndex }) => {
  const { results } = useHits({})
  const { hitPerIndexNumbers, setHitPerIndexNumbers } = useMultiIndexAlgoliaSearchOverviewContext()

  const hitPerIndexNumber: HitPerIndexNumber = useMemo(() => {
    return {
      nbHits: results.nbHits,
      nbPages: results.nbPages,
      indexName: algoliaIndex.indexName,
      indexLabel: algoliaIndex.label,
    }
  }, [results, algoliaIndex])

  const hitPerIndexNumbersContainIndex = hitPerIndexNumbers?.some((x) => x.indexName === algoliaIndex.indexName)
  const hitPerIndexNumbersChanged = hitPerIndexNumbersContainIndex
    ? hitPerIndexNumbers.some((x) => x.indexName === algoliaIndex.indexName && x.nbHits !== results.nbHits)
    : false

  useEffect(() => {
    if (!hitPerIndexNumbersContainIndex) {
      const newHitPerIndexNumbers =
        hitPerIndexNumbers?.length > 0 ? [...hitPerIndexNumbers, hitPerIndexNumber] : [hitPerIndexNumber]
      setHitPerIndexNumbers(newHitPerIndexNumbers)
    } else if (hitPerIndexNumbersContainIndex && hitPerIndexNumbersChanged) {
      const updatedHitPerIndexNumbers =
        hitPerIndexNumbers.length > 0
          ? [...hitPerIndexNumbers.filter((f) => f.indexName !== algoliaIndex.indexName), hitPerIndexNumber]
          : [hitPerIndexNumber]
      setHitPerIndexNumbers(updatedHitPerIndexNumbers)
    }
  }, [
    hitPerIndexNumbersContainIndex,
    hitPerIndexNumbersChanged,
    hitPerIndexNumbers,
    hitPerIndexNumber,
    setHitPerIndexNumbers,
    algoliaIndex,
  ])

  return null
}

export default HitPerIndexStateManager
