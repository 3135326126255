import React from 'react'
import RefinementDropdown from './dropdown'
import RefinementCheckbox from './checkbox'
import RefinementCheckboxIndexType from './checkboxIndexType'
import { RefinementProps } from './types'
import { FacetOption } from '../types'
import { MultipleIndex } from '@/components/GlobalSearch/types'

function renderDropdown(facet: FacetOption) {
  return (
    <RefinementDropdown
      key={facet.type}
      type={facet.type}
      label={facet.label}
      trueTranslation={facet.labelTrue}
      falseTranslation={facet.labelFalse}
      unit={facet.unit}
      filterType={facet.filterType}
    />
  )
}

function renderCheckbox(facet: FacetOption) {
  return (
    <RefinementCheckbox
      key={facet.type}
      type={facet.type}
      label={facet.label}
      trueTranslation={facet.labelTrue}
      falseTranslation={facet.labelFalse}
      showFalseValue={facet.showFalseValue}
      expandText={facet.expandText}
      collapseText={facet.collapseText}
    />
  )
}

function renderCheckboxIndexType(facet: FacetOption, multipleIndexes: MultipleIndex[]) {
  return (
    <RefinementCheckboxIndexType
      key={facet.type}
      type={facet.type}
      label={facet.label}
      multipleIndexes={multipleIndexes}
    />
  )
}

const Refinement: React.FC<React.PropsWithChildren<RefinementProps>> = ({
  facets,
  refinementComponentType,
  multipleIndexes,
}) => {
  if (facets.length === 0) {
    return null
  }

  return (
    <>
      {facets.map((facet) => (
        <>
          {refinementComponentType === 'dropdown' && renderDropdown(facet)}
          {refinementComponentType === 'checkbox' && facet.type !== 'multipleIndex' && renderCheckbox(facet)}
          {refinementComponentType === 'checkbox' &&
            facet.type === 'multipleIndex' &&
            renderCheckboxIndexType(facet, multipleIndexes)}
        </>
      ))}
    </>
  )
}

export default Refinement
