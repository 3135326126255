import React, { ReactNode } from 'react'
import { Index } from 'react-instantsearch-core'
import { MultipleIndex } from '@/components/GlobalSearch/types'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../../Context/multiIndexAlgoliaSearchOverviewContext'
import Hits from '../Default'
import { TwoColumnResultsHitWrapper } from '../styles'
import VirtualPagination from '../../Pagination/VirtualPagination'
import HitPerIndexStateManager from './hitPerIndexStateManager'

interface Props {
  multipleIndexes: MultipleIndex[]
  hitComponent: React.ComponentType<React.PropsWithChildren<any>>
  hitWrapperComponent?: React.ComponentType<React.PropsWithChildren<any>>
  emptyComponent?: ReactNode
  skeletonLoader: React.ComponentType<any>
}

const MultipleIndexHits: React.FC<React.PropsWithChildren<Props>> = ({
  multipleIndexes,
  emptyComponent: Empty,
  hitComponent: Hit,
  hitWrapperComponent: HitWrapper = TwoColumnResultsHitWrapper,
  skeletonLoader: SkeletonLoader,
}) => {
  const { indexTypeRefinementList, totalnbHits } = useMultiIndexAlgoliaSearchOverviewContext()
  return (
    <>
      {multipleIndexes.map((algoliaIndex: MultipleIndex) => {
        return (
          <Index indexName={algoliaIndex.indexName} key={algoliaIndex.indexName}>
            <>
              <HitPerIndexStateManager algoliaIndex={algoliaIndex} />
              {/* Make sure only the hits are displayed for the filters selected */}
              {(indexTypeRefinementList.length === 0 || indexTypeRefinementList.includes(algoliaIndex.label)) && (
                <Hits
                  hitComponent={Hit}
                  hitWrapperComponent={HitWrapper}
                  labelDefault={algoliaIndex.label}
                  skeletonLoader={SkeletonLoader}
                />
              )}
              <VirtualPagination />
            </>
          </Index>
        )
      })}
      {totalnbHits === 0 && <div>{Empty}</div>}
    </>
  )
}

export default MultipleIndexHits
