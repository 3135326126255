import connectClearRefinements, {
  ClearRefinementsConnectorParams,
  ClearRefinementsWidgetDescription,
} from 'instantsearch.js/es/connectors/clear-refinements/connectClearRefinements'
import connectStats, {
  StatsConnectorParams,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'
import { useConnector } from 'react-instantsearch-core'

type UseClearRefinementsProps = ClearRefinementsConnectorParams

function useClearRefinements(props: UseClearRefinementsProps) {
  return useConnector<ClearRefinementsConnectorParams, ClearRefinementsWidgetDescription>(
    connectClearRefinements,
    props,
  )
}

type UseStatsProps = StatsConnectorParams

function useStats(props: UseStatsProps) {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats, props)
}

export { useClearRefinements, useStats }
