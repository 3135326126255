// Source from: https://github.com/algolia/algoliasearch-client-javascript/blob/master/packages/requester-fetch/src/createFetchRequester.ts but supports overriding the whole hostname
import { Request, Requester, Response as AlgoliaResponse } from '@algolia/requester-common'

function isAbortError(error: unknown): boolean {
  return error instanceof Error && error.name === 'AbortError'
}

function getErrorMessage(error: unknown, abortContent: string): string {
  if (isAbortError(error)) {
    return abortContent
  }
  return error instanceof Error ? error.message : 'Network request failed'
}

export interface FetchRequesterOptions {
  readonly requesterOptions?: RequestInit
  readonly url?: string
}

export function createFetchRequester({ requesterOptions = {}, url }: FetchRequesterOptions = {}): Requester {
  return {
    async send(request: Request): Promise<Readonly<AlgoliaResponse>> {
      const abortController = new AbortController()
      const { signal } = abortController

      const createTimeout = (timeout: number): NodeJS.Timeout => {
        return setTimeout(() => {
          abortController.abort()
        }, timeout * 1000)
      }

      const connectTimeout = createTimeout(request.connectTimeout)

      let fetchRes: Response
      try {
        fetchRes = await fetch(url ?? request.url, {
          ...requesterOptions,
          method: request.method,
          headers: {
            ...(requesterOptions.headers ?? {}),
            ...request.headers,
          },
          body: request.data ?? null,
          redirect: 'manual',
          signal,
        })
      } catch (error) {
        return {
          status: 0,
          content: getErrorMessage(error, 'Connection timeout'),
          isTimedOut: isAbortError(error),
        }
      }

      clearTimeout(connectTimeout)

      createTimeout(request.responseTimeout)

      try {
        const content = await fetchRes.text()

        return {
          content,
          isTimedOut: false,
          status: fetchRes.status,
        }
      } catch (error) {
        return {
          status: 0,
          content: getErrorMessage(error, 'Socket timeout'),
          isTimedOut: isAbortError(error),
        }
      }
    },
  }
}
