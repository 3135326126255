import { theme } from '@damen/ui'
import styled from 'styled-components'

import { Inner } from '@/components/UI/Container'

const StyledInnerContainer = styled.div`
  ${Inner} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${theme.legacyMediaQueries.md} {
      flex-direction: row;
    }
  }
`

const OffsetElement = styled.div`
  height: 100px;
  transform: translateY(-100%);
  position: absolute;
`

const OverviewContainerDefault = styled.div`
  width: 100%;
`

const OverviewContainerFlex = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${theme.layout.spacingXXL};
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

const ResultsContainerDefault = styled.div`
  width: 100%;
`

const ResultsContainerflex = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  max-width: 830px;

  @media ${theme.legacyMediaQueries.md} {
    background: ${theme.colors.silverGrey100};
    padding: 40px;
    width: 66.66%;
  }
`

export {
  StyledInnerContainer,
  OffsetElement,
  ResultsContainerDefault,
  ResultsContainerflex,
  OverviewContainerFlex,
  OverviewContainerDefault,
}
