import styled from 'styled-components'
import { Size } from './types'

const getMarginBottom = (size: Size) => {
  if (size === 'small') {
    return '16px'
  }

  if (size === 'large') {
    return '24px'
  }
  return '0px'
}

const TitleWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})<{ size?: Size }>`
  margin-bottom: ${({ size }) => getMarginBottom(size)};
`

export { TitleWrapper }
