/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SearchFilter } from '@/components/GlobalSearch/types'
import { AlgoliaOverviewFilter } from '@/queries/schema.generated'

export const mapAlgoliaOverviewFilters = (filters: AlgoliaOverviewFilter[] | SearchFilter[]) => {
  return filters.map((filter) => ({
    label: filter.label,
    type: filter.type,
    // @ts-expect-error TODO Check the type
    componentType: filter.componentType,
    labelTrue: filter.labelTrue,
    labelFalse: filter.labelFalse,
    // @ts-expect-error TODO Check the type
    showFalseValue: filter.showFalseValue,
    // @ts-expect-error TODO Check the type
    expandText: filter.expandText,
    // @ts-expect-error TODO Check the type
    collapseText: filter.collapseText,
    // @ts-expect-error TODO Check the type
    unit: filter.unit,
    // @ts-expect-error TODO Check the type
    filterType: filter.filterType,
  }))
}
