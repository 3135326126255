import { motion } from 'framer-motion'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  padding: 30px;

  img {
    max-width: 232px;
  }

  h3 {
    margin: 32px 0 16px;
  }
`
const FlexMotionDiv = styled(motion.div)`
  display: flex;
  justify-content: center;
`

export { Wrapper, FlexMotionDiv }
