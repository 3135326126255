import React, { useMemo } from 'react'
import { SearchFilters } from '@/components/UI'
import { FacetProps } from './types'
import { CheckboxFilterWrapper } from './styles'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../Context/multiIndexAlgoliaSearchOverviewContext'
import { HitPerIndexNumber } from '../types'
import { MultipleIndex } from '@/components/GlobalSearch/types'

function checkHitPerIndexNumbers(hitPerIndexNumbers: HitPerIndexNumber[], index: MultipleIndex): unknown {
  const hitPerIndexNumber = hitPerIndexNumbers?.find((x) => x.indexName === index.indexName)
  return hitPerIndexNumber?.nbHits > 0
}

const CheckboxIndexTypeFacet: React.FC<React.PropsWithChildren<FacetProps>> = ({ type, label, multipleIndexes }) => {
  const { indexTypeRefinementList, setIndexTypeRefinementList, hitPerIndexNumbers } =
    useMultiIndexAlgoliaSearchOverviewContext()

  const initialValues = useMemo(
    () =>
      indexTypeRefinementList.map((x) => {
        return x
      }),
    [indexTypeRefinementList],
  )

  const onChange = (value: string) => {
    if (indexTypeRefinementList.includes(value)) {
      setIndexTypeRefinementList(indexTypeRefinementList.filter((e) => e !== value))
    } else {
      setIndexTypeRefinementList([...indexTypeRefinementList, value])
    }
  }

  const options = useMemo(
    () =>
      multipleIndexes
        .filter((f) => checkHitPerIndexNumbers(hitPerIndexNumbers, f))
        .map((index) => {
          const count = hitPerIndexNumbers.find((x) => x.indexName === index.indexName)?.nbHits
          return { label: index.label, value: index.label, count }
        }),
    [hitPerIndexNumbers, multipleIndexes],
  )

  const mappedFacets: any = [
    {
      key: type,
      expandText: `See all ${label}`,
      initiallyOpen: true,
      initialValues: [...initialValues],
      isCollapsible: true,
      options,
      title: label,
      onChange,
    },
  ]

  return (
    <CheckboxFilterWrapper key={type}>
      <SearchFilters facets={mappedFacets} />
    </CheckboxFilterWrapper>
  )
}

export default CheckboxIndexTypeFacet
