import React from 'react'
import { Heading, NextImage, Paragraph } from '@damen/ui'
import { FlexMotionDiv, Wrapper } from './styles'
import { Props } from './types'
import NoResultImage from '../../../../public/assets/images/looking-glass.png'

const EmptyResults: React.FC<React.PropsWithChildren<Props>> = ({
  img = NoResultImage,
  text = "There don't seem to be any matches with this search query",
  title = 'Sorry we couldn’t find any matches for that',
}) => (
  <FlexMotionDiv animate={{ opacity: 1, transition: { delay: 0.5 } }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
    <Wrapper>
      <NextImage src={img} alt="No results" width={232} height={55} aspectRatio="232:55" />
      <Heading.HeadingThree>{title}</Heading.HeadingThree>
      <Paragraph.Default>{text}</Paragraph.Default>
    </Wrapper>
  </FlexMotionDiv>
)

export default EmptyResults
