import React from 'react'
import { SearchField } from '@damen/ui'
import { useSearchBox } from 'react-instantsearch-core'
import debounce from 'lodash/debounce'
import { SearchFieldWrapper } from './styles'
import { SeachBoxProps } from './types'

const SearchBox: React.FC<React.PropsWithChildren<SeachBoxProps>> = ({ placeholder }) => {
  const { refine, clear, query } = useSearchBox()
  const refineFunction = (x: string) => (x.length > 0 ? refine(x) : clear())
  const debouncedRefineFunction = debounce(refineFunction, 500)
  return (
    <SearchFieldWrapper>
      <SearchField placeholder={placeholder} onChange={debouncedRefineFunction} value={query} />
    </SearchFieldWrapper>
  )
}

export default SearchBox
