import { Icon } from '@damen/ui'
import React, { useCallback, useEffect } from 'react'
import { useCurrentRefinements } from 'react-instantsearch-core'
import { useGlobalSearchContext } from '@/components/GlobalSearch/Context/globalSearchContext'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../Context/multiIndexAlgoliaSearchOverviewContext'
import { useClearRefinements, useStats } from '../Hooks'
import { ClearButton, FilterButton, Label, ResultsText, SelectedFiltersWrapper, SelectedFilters } from './styles'
import { Props } from './types'

const CurrentRefinementList: React.FC<React.PropsWithChildren<Props>> = ({
  resultsPlaceholder,
  facets,
  filtersWrapper: FiltersWrapper = SelectedFiltersWrapper,
  showResultCount = true,
  keepSpaceWhenCountIsZero = true,
}) => {
  const { items, refine } = useCurrentRefinements()
  const { refine: clearRefine } = useClearRefinements({})
  const { nbHits: resultCount } = useStats({})
  const { indexTypeRefinementList, setIndexTypeRefinementList } = useMultiIndexAlgoliaSearchOverviewContext()
  const globalSearchContext = useGlobalSearchContext()

  const customRefine = (value: string) => {
    if (indexTypeRefinementList.includes(value)) {
      setIndexTypeRefinementList(indexTypeRefinementList.filter((e) => e !== value))
    } else {
      setIndexTypeRefinementList([...indexTypeRefinementList, value])
    }
  }

  const clearCustomRefine = useCallback(() => {
    setIndexTypeRefinementList([])
  }, [setIndexTypeRefinementList])

  const handleClearRefine = useCallback(() => {
    clearCustomRefine()
    clearRefine()
  }, [clearCustomRefine, clearRefine])

  useEffect(() => {
    if (globalSearchContext) {
      handleClearRefine()
    }
  }, [globalSearchContext, globalSearchContext?.activeTab.layoutOption, handleClearRefine])

  const showFilters = !keepSpaceWhenCountIsZero ? items.length + indexTypeRefinementList.length > 0 : true

  if (!showFilters) {
    return null
  }

  return (
    <FiltersWrapper>
      {showResultCount && items.length > 0 && (
        <ResultsText>
          {resultCount} {resultsPlaceholder}
        </ResultsText>
      )}
      <SelectedFilters>
        {(items.length > 0 || indexTypeRefinementList.length > 0) && (
          <>
            {items.map((item) => {
              const { refinements } = item
              return refinements.map((refinement) => {
                const { label, attribute } = refinement
                const booleanFacet = facets?.find((facet) => facet.type === attribute)
                const labelTrue = booleanFacet?.labelTrue
                const labelFalse = booleanFacet?.labelFalse
                const translatedLabel = () => {
                  if (refinement.type === 'numeric') {
                    return `${booleanFacet.label} ${label} ${booleanFacet?.unit}`
                  }
                  switch (label) {
                    case 'true':
                      if (labelTrue) {
                        return labelTrue
                      }
                      return label

                    case 'false':
                      if (labelFalse) {
                        return labelFalse
                      }
                      return label

                    default:
                      return label
                  }
                }
                return (
                  <FilterButton key={`${attribute}-${translatedLabel()}`} onClick={() => refine(refinement)}>
                    <Icon.Close width={10} height={10} />
                    <Label>{translatedLabel()}</Label>
                  </FilterButton>
                )
              })
            })}
            {indexTypeRefinementList.map((item) => {
              return (
                <FilterButton key={item} onClick={() => customRefine(item)}>
                  <Icon.Close width={10} height={10} />
                  <Label>{item}</Label>
                </FilterButton>
              )
            })}
            <ClearButton onClick={handleClearRefine}>Clear</ClearButton>
          </>
        )}
      </SelectedFilters>
    </FiltersWrapper>
  )
}

export default CurrentRefinementList
