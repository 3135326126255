import React, { useMemo } from 'react'
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch-core'
import { useRouter } from 'next/router'
import simpleStateMapping from 'instantsearch.js/es/lib/stateMappings/simple'
import { history } from 'instantsearch.js/es/lib/routers'
import type { InstantSearchProps } from '../types'
import { createDefaultAlgoliaSearchClient } from '@/lib/algolia'

// There is an issue with instantsearch query params and nextjs router state.
// Have tried the folowing to resolve an issue where the contact modal does not close when there are
// filters selected on an overview (hash is removed from url but is not picked up by onHashComplete event):
// https://github.com/algolia/instantsearch/issues/5241
// The fix does not work and also casues another issue where you cannot remove your selected filters from the url

// https://github.com/algolia/react-instantsearch/blob/master/examples/hooks-next/pages/index.tsx
const searchClient = createDefaultAlgoliaSearchClient()

const InstantSearchComponent: React.FC<React.PropsWithChildren<InstantSearchProps>> = ({ indexName, children }) => {
  const router = useRouter()
  const routing = useMemo(
    () => ({
      router: history({
        getLocation() {
          if (typeof window === 'undefined') {
            return new URL(router.asPath) as unknown as Location
          }
          return window.location
        },
        writeDelay: 400,
        cleanUrlOnDispose: false,
      }),
      stateMapping: simpleStateMapping(),
    }),
    [router],
  )

  // Starting from the next major version, InstantSearch will change how widgets state is preserved when they are removed.
  // InstantSearch will keep the state of unmounted widgets to be usable by other widgets with the same attribute.
  // We recommend setting `future.preserveSharedStateOnUnmount` to true to adopt this change today.
  const futureSettings = {
    preserveSharedStateOnUnmount: true,
  }

  return (
    <InstantSearchSSRProvider>
      <InstantSearch indexName={indexName} searchClient={searchClient} routing={routing} future={futureSettings}>
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}

export default InstantSearchComponent
