import { theme } from '@damen/ui'
import styled from 'styled-components'

const DataListWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['compact'].includes(prop),
})<{ compact?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  span {
    width: ${({ compact }) => (compact ? '30%' : '50%')};
    padding-right: ${theme.layout.spacing};
  }

  div {
    width: ${({ compact }) => (compact ? '70%' : '50%')};
    padding-left: ${theme.layout.spacing};
  }

  span,
  div {
    &:not(:last-of-type) {
      margin-bottom: ${theme.layout.spacingM};
    }
  }
`

export { DataListWrapper }
