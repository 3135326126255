import React, { createContext, useContext, useMemo, useState } from 'react'
import { SearchTab } from '@/components/GlobalSearch/types'
import { SearchState } from '@/types'

interface GlobalSearchContextProps {
  activeTab: SearchTab
  setActiveTab?: React.Dispatch<React.SetStateAction<SearchTab>>
  showAutosuggest: boolean
  setShowAutosuggest: React.Dispatch<React.SetStateAction<boolean>>
  showSearchResult: boolean
  setShowSearchResult: React.Dispatch<React.SetStateAction<boolean>>
  searchState: SearchState
  setSearchState: React.Dispatch<React.SetStateAction<SearchState>>
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  tabs: SearchTab[]
}

const GlobalSearchContext = createContext<GlobalSearchContextProps>(null)

export interface SearchContextProviderProps {
  tabs: SearchTab[]
  children: React.ReactNode | React.ReactNode[] | null
}

const SearchContextProvider = ({ children, tabs }: SearchContextProviderProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchState, setSearchState] = useState<SearchState>('initial')
  const [showAutosuggest, setShowAutosuggest] = useState<boolean>(false)
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(tabs[0])

  const providerValue = useMemo(
    () => ({
      searchQuery,
      setSearchQuery,
      searchState,
      setSearchState,
      showAutosuggest,
      setShowAutosuggest,
      showSearchResult,
      setShowSearchResult,
      activeTab,
      setActiveTab,
      tabs,
    }),
    [
      searchQuery,
      setSearchQuery,
      searchState,
      setSearchState,
      showAutosuggest,
      setShowAutosuggest,
      showSearchResult,
      setShowSearchResult,
      activeTab,
      setActiveTab,
      tabs,
    ],
  )

  return <GlobalSearchContext.Provider value={providerValue}>{children}</GlobalSearchContext.Provider>
}

export const useGlobalSearchContext = () => {
  return useContext(GlobalSearchContext)
}

export default SearchContextProvider
