import React from 'react'
import DamenPagination from '@/components/UI/Pagination'
import { IndexPaginationProps as Props } from '../../types'
import { useMultiIndexAlgoliaSearchOverviewContext } from '../../Context/multiIndexAlgoliaSearchOverviewContext'

const MultipleIndexPagination = ({ onPaginate, paginationMargin, paginationMarginMedia }: Props) => {
  const { nbPages, currentPage, setCurrentPage } = useMultiIndexAlgoliaSearchOverviewContext()
  const handleClick = (newPage: number) => {
    setCurrentPage(newPage)
    if (onPaginate) {
      onPaginate()
    }
  }

  return (
    <DamenPagination
      initialIndex={currentPage - 1}
      pages={nbPages}
      onClick={(newPage) => handleClick(newPage)}
      paginationMargin={paginationMargin}
      paginationMarginMedia={paginationMarginMedia}
    />
  )
}

export default MultipleIndexPagination
