import { BaseHit, Hit } from 'instantsearch.js'
import React, { createContext, useContext, useMemo, useState } from 'react'

interface Props {
  hits: Hit<BaseHit>[]
  setHits: React.Dispatch<React.SetStateAction<Hit<BaseHit>[]>>
  totalIndexHits: number
}

const Context = createContext<Props>(null)

export interface IndexAlgoliaSearchOverviewContextProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

const IndexAlgoliaSearchOverviewContextProvider = ({ children }: IndexAlgoliaSearchOverviewContextProviderProps) => {
  const [hits, setHits] = useState<Hit<BaseHit>[]>(null)

  const totalIndexHits = hits?.length ?? 0

  const providerValue = useMemo(
    () => ({
      hits,
      setHits,
      totalIndexHits,
    }),
    [hits, totalIndexHits],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

export const useIndexAlgoliaSearchOverviewContext = () => {
  return useContext(Context)
}

export default IndexAlgoliaSearchOverviewContextProvider
