import React from 'react'
import { usePagination } from 'react-instantsearch-core'
import DamenPagination from '@/components/UI/Pagination'
import { IndexPaginationProps as Props } from '../../types'

const DefaultPagination = ({ onPaginate, paginationMargin, paginationMarginMedia }: Props) => {
  const { nbPages, refine, currentRefinement } = usePagination()
  const handleClick = (newPage: number) => {
    // Damen Component is 1 based, though Algolia is 0 based
    refine(newPage - 1)

    if (onPaginate) {
      onPaginate()
    }
  }
  return (
    <DamenPagination
      initialIndex={currentRefinement}
      pages={nbPages}
      onClick={(newPage) => handleClick(newPage)}
      paginationMargin={paginationMargin}
      paginationMarginMedia={paginationMarginMedia}
    />
  )
}

export default DefaultPagination
