import algoliasearch from 'algoliasearch/lite'
import { getPublicEnv } from '@/utils/env'
import { createFetchRequester } from './createFetch'

const DEFAULT_NEXT_PUBLIC_SEARCH_ENDPOINT = '/graphql/search'

const createClientBackendAlgoliaSearchClient = () => {
  const searchEndpoint = `${
    getPublicEnv('NEXT_PUBLIC_SEARCH_ENDPOINT') ?? DEFAULT_NEXT_PUBLIC_SEARCH_ENDPOINT
  }?site=${getPublicEnv('NEXT_PUBLIC_SITE')}`

  const publicHostname = getPublicEnv('NEXT_PUBLIC_PUBLICHOSTNAME')

  const searchEndpointUrl = !searchEndpoint || searchEndpoint.startsWith('/') ? undefined : new URL(searchEndpoint)

  return algoliasearch('NOT-REQUIRED', 'NOT-REQUIRED', {
    hosts: [
      {
        url: searchEndpointUrl?.host ?? publicHostname ?? window.location.host,
        protocol: searchEndpointUrl?.protocol ?? 'https',
      },
    ],
    requester: createFetchRequester({ url: searchEndpoint, requesterOptions: { method: 'POST' } }),
    authMode: 0,
    headers: {
      Authorization: `Bearer ${getPublicEnv('NEXT_PUBLIC_FRONTEND_TOKEN')}`,
    },
    // Default is {"connect": 1, "read": 2, "write": 30}
    timeouts: {
      connect: 5,
      read: 10,
      write: 50,
    },
  })
}

const createFrontendAlgoliaSearchClient = () => {
  return algoliasearch(process.env.ALGOLIA_APPLICATION_ID, process.env.ALGOLIA_QUERY_KEY)
}
export { createClientBackendAlgoliaSearchClient as createDefaultAlgoliaSearchClient, createFrontendAlgoliaSearchClient }
